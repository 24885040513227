"use client";
import { useEffect, useState } from "react";
import { signIn } from "next-auth/react";
import toast from "react-hot-toast";
import isEmail from "validator/lib/isEmail";

export default function LoginForm(props: { loggedIn: boolean }) {
  const [email, setEmail] = useState("");
  const [isSubmitting, setSubmittingState] = useState(false);

  const validate = isEmail(email);

  const handleEmailChange = (event: any) => setEmail(event.target.value);

  const signInWithEmail = async (event: any) => {
    if(!validate) return;
    event.preventDefault();
    setSubmittingState(true);
    let response: any;
    try {
      response = await signIn("emailcustom", {
        email,
        redirect: false,
      });
    } catch (err: any) {
      console.error(err);
    }

    if (!response || response?.error === null) {
      toast.success(`Please check your email (${email}) for the login link.`, {
        duration: 5000,
      });
      setEmail("");
    }

    setSubmittingState(false);
  };

  return (
    <form className="flex w-full flex-col space-y-3">
      <input
        className="rounded border px-3 py-2"
        onChange={handleEmailChange}
        placeholder="user@email.com"
        type="email"
        name="email"
        value={email}
      />
      <button
        className="rounded bg-primary py-2 text-white hover:bg-primary-active disabled:opacity-75"
        disabled={props.loggedIn || isSubmitting || !validate}
        onClick={signInWithEmail}
      >
        {props.loggedIn
          ? "Checking session..."
          : isSubmitting
          ? "Sending the link..."
          : "Sign in with Email"}
      </button>
    </form>
  );
}
