"use client";

import { signIn } from "next-auth/react";
import Image from "next/image";

export default function AuthLink(props: { name: string; id: string }) {
  if(props.id == "google") return <button  onClick={async () => {
    await signIn(props.id);
  }} className="py-3 border flex gap-2 relative items-center justify-center border-slate-200 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow transition duration-150">
  <Image width={24} height={24} className="absolute top-3 left-4 w-6 h-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo"/>
  <span>Login with Google</span>
</button>

  return (
    <button
      className="rounded border bg-gray-300/20 py-2 hover:bg-gray-400/30 disabled:opacity-75"
      onClick={async () => {
        console.log("click", props.id);
        await signIn(props.id);
      }}
    >
      {props.name}
    </button>
  );
}
